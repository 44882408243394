// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgAttachFile = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.778 2.75c-.862 0-1.69.343-2.3.953l-9.19 9.19a5.253 5.253 0 1 0 7.43 7.429l9.19-9.19a.75.75 0 0 1 1.06 1.06l-9.19 9.19a6.754 6.754 0 0 1-9.55-9.55l9.19-9.19a4.752 4.752 0 0 1 6.72 6.72l-9.2 9.19a2.751 2.751 0 0 1-3.89-3.89l8.49-8.48a.75.75 0 0 1 1.06 1.06l-8.49 8.48a1.252 1.252 0 0 0 1.77 1.77l9.2-9.19a3.252 3.252 0 0 0-2.3-5.552"
      clipRule="evenodd"
    />
  </svg>
);
export const AttachFileIcon = forwardRef(SvgAttachFile);
